section {
  padding: 100px 0;
}

.section-bg {
  background-color: #CFE2F3;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #032654;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #004298;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# home
--------------------------------------------------------------*/

/* Styles pour les boutons dans la section #home */
.home .btn-block {
  background-color: white; /* Couleur de fond blanche */
  color: black; /* Couleur du texte */
  font-size: 20px; /* Taille du texte pour avoir de gros boutons */
  padding: 45px 30px; /* Taille du bouton */
  border: 2px solid black; /* Bordure noire */
  transition: background-color 0.3s, color 0.3s; /* Transition pour l'effet de survol */
  display: block; /* Pour s'assurer que le bouton prenne toute la largeur du conteneur */
  width: 100%; /* Pour s'assurer que le bouton prenne toute la largeur du conteneur */
  text-align: center; /* Centrer le texte dans le bouton */
}

.home .btn-block:hover {
  background-color: #004298; /* Couleur de fond bleue au survol */
  color: white; /* Couleur du texte blanche au survol */
}

/* Styles pour centrer les boutons au milieu de la page */
.home .row {
  display: flex;
  justify-content: center; /* Centrer les boutons horizontalement */
  align-items: center; /* Centrer les boutons verticalement */
  min-height: 60vh; /* Assurer que la hauteur minimale de la section est de 100% de la hauteur de la fenêtre */
}

/*--------------------------------------------------------------
# navbar
--------------------------------------------------------------*/

.navbar .btn-block {
  background-color: #fff; /* Couleur de fond blanche */
  color: #004298; /* Couleur du texte */
  font-size: 20px; /* Taille du texte pour avoir de gros boutons */
  padding: 10px 20px; /* Taille du bouton (ajustée pour la navbar) */
  border: 2px solid #fff; /* Bordure noire */
  transition: background-color 0.3s, color 0.3s; /* Transition pour l'effet de survol */
  display: inline-block; /* Pour s'assurer que le bouton prenne la largeur du contenu */
  text-align: center; /* Centrer le texte dans le bouton */
}

.navbar .btn-block:hover {
  background-color: #004298; /* Couleur de fond bleue au survol */
  color: #fff; /* Couleur du texte blanche au survol */
}

.navbar .logout-btn {
  display: inline-block;
  padding: 8px 20px; /* Ajustez le rembourrage du bouton si nécessaire */
  color: #dd0b0b;
  transition: none;
  font-size: 14px; /* Ajustez la taille de la police du bouton si nécessaire */
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid #dd0b0b;
  background: #fff;
}

.navbar .logout-btn:hover {
  background: #dd0b0b;
  border: 1px solid #dd0b0b;
  color: #fff;
}


/*--------------------------------------------------------------
# users
--------------------------------------------------------------*/
.users Table {
  width: 100%;
  height: 80vh;
  border-collapse: collapse; /* Pour fusionner les bordures des cellules */
}

.users Table th,
.users Table td {
  padding: 10px; /* Ajustez le rembourrage selon vos besoins */
  text-align: left; /* Ajustez l'alignement du texte si nécessaire */
  border: 1px solid #ddd; /* Ajoutez une bordure autour de chaque cellule */
}

.users Table th {
  background-color: #f2f2f2; /* Couleur de fond pour les en-têtes de colonne */
}

.users .edit-btn {
  display: inline-block;
  padding: 8px 20px; /* Ajustez le rembourrage du bouton si nécessaire */
  border-radius: 50px;
  color: #004298;
  transition: none;
  font-size: 14px; /* Ajustez la taille de la police du bouton si nécessaire */
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid #004298;
  background: #fff;
}

.users .edit-btn:hover {
  background: #004298;
  color: #fff;
}

.users .delete-btn {
  display: inline-block;
  padding: 8px 20px; /* Ajustez le rembourrage du bouton si nécessaire */
  border-radius: 50px;
  color: #dd0b0b;
  transition: none;
  font-size: 14px; /* Ajustez la taille de la police du bouton si nécessaire */
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid #dd0b0b;
  background: #fff;
}

.users .delete-btn:hover {
  background: #dd0b0b;
  border: 1px solid #dd0b0b;
  color: #fff;
}

.users .save-btn {
  display: inline-block;
  padding: 8px 20px; /* Ajustez le rembourrage du bouton si nécessaire */
  border-radius: 50px;
  color: #127f33;
  transition: none;
  font-size: 14px; /* Ajustez la taille de la police du bouton si nécessaire */
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid #127f33;
  background: #fff;
}

.users .save-btn:hover {
  background: #127f33;
  border: 1px solid #127f33;
  color: #fff;
}
.users .cancel-btn {
  display: inline-block;
  padding: 8px 20px; /* Ajustez le rembourrage du bouton si nécessaire */
  border-radius: 50px;
  color: #fff;
  transition: none;
  font-size: 14px; /* Ajustez la taille de la police du bouton si nécessaire */
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid #dd0b0b;
  background: #dd0b0b;
}

.users .cancel-btn:hover {
  background: #fff;
  border: 1px solid #dd0b0b;
  color: #dd0b0b;
}

.users .success-message {
  background-color: #d4edda; /* Couleur de fond verte */
  color: #155724; /* Couleur du texte */
  padding: 10px 15px; /* Espacement interne */
  border-radius: 5px; /* Bord arrondi */
  margin-bottom: 20px; /* Marge en bas */
}

  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }

/*--------------------------------------------------------------
# Themes
--------------------------------------------------------------*/


.Themes .add-btn {
  display: inline-block;
  padding: 8px 20px; /* Ajustez le rembourrage du bouton si nécessaire */
  border-radius: 50px;
  color: #004298;
  transition: none;
  font-size: 18px; /* Ajustez la taille de la police du bouton si nécessaire */
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid #004298;
  background: #fff;
}

.Themes .add-btn:hover {
  background: #004298;
  color: #fff;
}

.Themes .edit-btn {
  display: inline-block;
  padding: 8px 20px; /* Ajustez le rembourrage du bouton si nécessaire */
  border-radius: 50px;
  color: #004298;
  transition: none;
  font-size: 14px; /* Ajustez la taille de la police du bouton si nécessaire */
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid #004298;
  background: #fff;
}

.Themes .edit-btn:hover {
  background: #004298;
  color: #fff;
}

/*--------------------------------------------------------------
# addtheme
--------------------------------------------------------------*/


.addtheme .add-btn {
  display: inline-block;
  padding: 8px 20px; /* Ajustez le rembourrage du bouton si nécessaire */
  border-radius: 50px;
  color: #004298;
  transition: none;
  font-size: 18px; /* Ajustez la taille de la police du bouton si nécessaire */
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid #004298;
  background: #fff;
}

.addtheme .add-btn:hover {
  background: #004298;
  color: #fff;
}

/*--------------------------------------------------------------
# login
--------------------------------------------------------------*/


.login .login-btn {
  display: inline-block;
  padding: 8px 20px; /* Ajustez le rembourrage du bouton si nécessaire */
  border-radius: 50px;
  color: #004298;
  transition: none;
  font-size: 18px; /* Ajustez la taille de la police du bouton si nécessaire */
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid #004298;
  background: #fff;
}

.login .login-btn:hover {
  background: #004298;
  color: #fff;
}

/*--------------------------------------------------------------
# Update
--------------------------------------------------------------*/


.update .add-btn {
  display: inline-block;
  padding: 8px 20px; /* Ajustez le rembourrage du bouton si nécessaire */
  border-radius: 50px;
  color: #004298;
  transition: none;
  font-size: 18px; /* Ajustez la taille de la police du bouton si nécessaire */
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid #004298;
  background: #fff;
}

.update .add-btn:hover {
  background: #004298;
  color: #fff;
}

.update .edit-btn {
  display: inline-block;
  padding: 8px 20px; /* Ajustez le rembourrage du bouton si nécessaire */
  border-radius: 50px;
  color: #004298;
  transition: none;
  font-size: 14px; /* Ajustez la taille de la police du bouton si nécessaire */
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid #004298;
  background: #fff;
}

.update .edit-btn:hover {
  background: #004298;
  color: #fff;
}

.update label {
  font-weight: bold;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure the spinner is on top */
}

.spinner-container {
  text-align: center;
  color: white;
}
